import '../styles/ArticleMainPage.css';
import {Link, useNavigate} from "react-router-dom";


const ArticleMainPage = (props) => {

    const DEBUG = false;
    let path = "https://lifestylespros.com";
    if (DEBUG) {
        path = "http://localhost:3000";
    }
    const navigate = useNavigate();

    const goTo = function () {
        const path = "/article" + props.id;
        navigate(path);
    }

    return (
        <div className='article-container'>
            {/*<div className='photo-container'> <img className='article-photo' src={`${path}/posts-images/${props.image}`} alt='null' onClick={goTo} fetchpriority="high"/> </div>*/}
            {/*without forward to article by click*/}
            <div className='photo-container'> <img className='article-photo' src={`${path}/posts-images/${props.image}`} alt='null' fetchpriority="high"/> </div>
            {/*<div className='article-title' onClick={goTo}>{props.title}</div>*/}
            <Link to={`/article${props.id}`} className='article-title' >{props.title}</Link>
            <div className='article-content'>
                <p>
                    {props.content}
                </p>
            </div>
            <Link to={`/article${props.id}`} className='continue' >continue Reading</Link>
        </div>
    );
}

export default ArticleMainPage